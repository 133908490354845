import React from "react";
import { StyledRating } from "../../PropertyElements/Reviews";
import styles from "./authour.module.scss";
import userImage from "../../../img/team/4.jpg";
import EmailIcon from "@mui/icons-material/Email";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface AuthourWidgetProps {
  fullName?: string;
  rating?: number;
  description?: string;
  mobile?: string | number;
  email?: string;
  image?: {
    src: string;
    alt?: string;
  };
  imageURL?: string;
  listerId?: string;
  history: any;
}

const AuthourWidget: React.FC<AuthourWidgetProps> = ({
  fullName = "Rosalina D. Willaimson",
  rating,
  description = ` Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
    Veritatis distinctio, odio, eligendi suscipit reprehenderit atque.
    `,
  mobile = "XXXXXXXXXXX",
  email = "JohnSmith@gmail.com",
  image,
  imageURL,
  listerId,
  history,
}) => {
  let alt = image ? image.alt : "default agent picture";

  // Token
  const token = localStorage.getItem("token");

  const handleLister = () => {
    history.push("/agent-properties");
    if (listerId) {
      localStorage.setItem("listerId", listerId);
    }
  };

  return (
    <div className="widget ltn__author-widget">
      <div className="ltn__author-widget-inner text-center">
        <h3>Property Listed By:</h3>
        {/* <div className={styles.logoCont}></div> */}
        <div className={styles.namediv}>
          <div>
            {imageURL ? (
              <div className={styles.imgContainer}>
                <img src={imageURL} alt={alt} className={styles.img} />
              </div>
            ) : (
              <div className={styles.dummy}>
                <FontAwesomeIcon icon={faUser as IconProp} />
              </div>
            )}
          </div>
          <div className={styles.name}>
            <h6>{fullName}</h6>
            {
              <div className="product-ratting mui-homes-rating">
                <StyledRating
                  name="half-rating"
                  value={rating}
                  precision={0.5}
                  size="small"
                  readOnly
                />
              </div>
            }
          </div>
        </div>
        <div>
          <button
            className="btn theme-btn-1"
            disabled={!listerId}
            onClick={() => handleLister()}
          >
            {"View all listings"}
          </button>
        </div>

        {/* <p>{description}</p> */}
        {/* <div className="ltn__social-media">
          <address className={styles.address}>
            <span>
              <i className="fa fa-phone"></i> Mobile :{" "}
              {token ? mobile : "XXXXXXXXXXX"}
              {token ? "XXXXXXXXXXX" : "XXXXXXXXXXX"}
            </span>
            <br />
            <span>
              <EmailIcon sx={{ fontSize: "16px" }} /> Mail:
              {token ? (
                <a href={`mailto:${email}`}>{` ${email}`}</a>
                "XXXXX@XXXXXXX"
              ) : (
                "XXXXX@XXXXXXX"
              )}
            </span>
          </address>
        </div> */}
      </div>
    </div>
  );
};

export default AuthourWidget;
