import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./mortgage.module.scss";

const MarkOptions = ({
  options,
  click,
  closeFunction,
}: {
  options: {
    icon: string;
    text: string;
  }[];
  click: (selection: string) => void;
  closeFunction: () => void;
}) => {
  return (
    <div className={styles["out-contain"]}>
      <div className={styles["cancel-container-I"]}>
        <div
          className={styles["cancel-button"]}
          role="button"
          onClick={closeFunction}
        >
          <CloseIcon />
        </div>
      </div>
      <div className={styles.contain}>
        <div className={styles["sel-contain"]}>
          <span>SELECT</span>
        </div>
        {options &&
          options.map((option) => (
            <div
              key={option.text}
              className={styles["item-contain"]}
              role="button"
              onClick={() => click(option.text)}
            >
              <img src={option.icon} />
              <span>{option.text}</span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MarkOptions;
