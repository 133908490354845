import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import reportStyles from "./reportlisting.module.scss";
import close from "../../assets/close.svg";
import TextArea from "../../components/FormElements/TextArea";
import { reportProperty } from "../../swr/reports";
import { toast } from "react-toastify";

type ReportModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  id: number;
  token: string
};

const ReportModal: React.FC<ReportModalProps> = ({
  isOpen,
  handleClose,
  id,
  token
}) => {
  const [category, setCategory] = useState("");
  const [reason, setReason] = useState("");

  const isFormComplete = !!category && reason.trim() !== "";

  const handleCategorySelect = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
  };

  const handleSubmit = async () => {
    const { data, error } = await reportProperty(
      { propertyId: id, reasons: [category, reason] },
      token
    );

    console.log(data);

    if (data) {
      console.log("Report Submitted successfully");
      toast.success("Report submitted successfully");
      console.log(data.data);
      setCategory("");
      setReason("");
      handleClose();
    }
    if (error) {
      console.error("Error submitting report", error.message);
      toast.error("Error submitting report");
    }
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <div className={reportStyles.modalContainer}>
          <div className={reportStyles.flexEnd}>
            <img src={close} alt="" onClick={handleClose} />
          </div>
          <span className={reportStyles.title}>Report Listing</span>
          <FormControl
            fullWidth
            style={{ marginBottom: 20 }}
            sx={{
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& fieldset": {
                  borderColor: "#91EA91", // Change the border color to green on focus
                },
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#91EA91", // Green label text on focus
              },
            }}
          >
            <InputLabel id="select-cate-label">Select Category</InputLabel>
            <Select
              label="Select Category"
              labelId="select-cate-label"
              value={category}
              onChange={handleCategorySelect}
              id="select-drop"
            >
              <MenuItem value={"Inaccurate Information"}>
                Inaccurate Information
              </MenuItem>
              <MenuItem value={"Fraudulent Listing"}>
                Fraudulent Listing
              </MenuItem>
              <MenuItem value={"Inappropriate Content"}>
                Inaappropriate Content
              </MenuItem>
            </Select>
          </FormControl>
          <TextArea
            name="additional info"
            value={reason}
            onChange={handleReasonChange}
            placeholder="Provide Additional Information...."
          />
          <div className={reportStyles.btnContainer}>
            <button
              className={
                isFormComplete
                  ? reportStyles.submitBtn
                  : reportStyles.previewBtn
              }
              onClick={handleSubmit}
              disabled={!isFormComplete}
            >
              {isFormComplete ? "Submit" : "Preview"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReportModal;
