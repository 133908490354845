import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AboutUs from "../components/AboutUs";
//import { About } from "../components/About";
import Accordion from "../components/Accordion";
import AreaProperties from "../components/AreaProperties";
import CallToAction from "../components/CallToAction";
import CounterUp from "../components/CounterUp";
import FeatureArea from "../components/FeatureArea";
import FeaturedProperties from "../components/FeaturedProperties";
import ImageNav from "../components/ImageNav";
import landmarkData from "../components/ImageNav/defaulLandmarks";
//import { Jumbotron } from "../components/Jumbotron";
import LandmarkCard from "../components/LandmarkCard";
import Layout from "../components/Layout";
import ProductSlider from "../components/ProductSlider/slider2";
import propertyData from "../components/PropertyCard/defaultData";
import SliderArea from "../components/SliderArea";
import TodaySells from "../components/TodaySells";
//import OurServices from "../components/OurServices";
import Aminities from "../sections/Amenities";
import ExploreNeighbour from "../sections/ExploreNeighbour";
import { RootState } from "../state/store";
import { fetchProperties } from "../state/thunks/property";
import { Property } from "../types/Property";
import { Amenity } from "../types/amenity";
import HvacIcon from "@mui/icons-material/Hvac";
import GrassIcon from "@mui/icons-material/Grass";
import BoltIcon from "@mui/icons-material/Bolt";
import KitchenIcon from "@mui/icons-material/Kitchen";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import MovieIcon from "@mui/icons-material/Movie";
import PoolIcon from "@mui/icons-material/Pool";
import WifiIcon from "@mui/icons-material/Wifi";
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import ShowerIcon from "@mui/icons-material/Shower";
import { AmenityCardProps } from "../components/AmenityCard";
import { getProperties, useProperties } from "../swr/properties";
import SplashScreenIncentives from "../components/SplashScreen/SplashScreenIncentive";
// import SplashScreenHome from "../components/SplashScreen/SplashScreenHome";
import Modal from "react-modal";
import Testimonials from "../sections/Testimonials";

const HomePage: React.FC<any> = () => {
  const [allProperties, setAllProperties] = useState<Property[]>([]);
  const [modalIsOpen, setModalIsOpen] = React.useState(true);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const fetchAllProperties = async () => {
    const response = await getProperties(1, 1000);
    if (response && response.item1) {
      setAllProperties(response.item1.filter((prop: { availability: boolean; }) => prop.availability === true));
    }
  };

  useEffect(() => {
    fetchAllProperties();
  }, []);

  // const dispatch = useDispatch();
  // const { properties, loading, error } = useSelector((state: RootState)=> state.property);

  // // Fetch properties on page load
  // React.useEffect(()=>{
  //     dispatch(fetchProperties());
  // },[]);

  const token = localStorage.getItem("token") || undefined;
  const { properties, isLoading, isError } = useProperties(token, 1, 1000000);

  useEffect(() => {
    if (
      properties &&
      properties.length < 1 &&
      allProperties &&
      allProperties.length < 1
    ) {
      fetchAllProperties();
    }
  }, [properties, allProperties]);

  let testProperties: Property[] = [
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
    propertyData,
  ];

  // Icon hash
  let iconHash = {
    [Amenity.AirConditioning]: <HvacIcon sx={{ fontSize: "40px" }} />,
    [Amenity.Backyard]: <GrassIcon sx={{ fontSize: "40px" }} />,
    [Amenity.Electricity]: <BoltIcon sx={{ fontSize: "40px" }} />,
    [Amenity.EquippedKitchen]: <KitchenIcon sx={{ fontSize: "40px" }} />,
    [Amenity.Gym]: <FitnessCenterIcon sx={{ fontSize: "40px" }} />,
    [Amenity.Laundry]: <LocalLaundryServiceIcon sx={{ fontSize: "40px" }} />,
    [Amenity.MediaRoom]: <MovieIcon sx={{ fontSize: "40px" }} />,
    [Amenity.Pool]: <PoolIcon sx={{ fontSize: "40px" }} />,
    [Amenity.WIFI]: <WifiIcon sx={{ fontSize: "40px" }} />,
    [Amenity.WashingMachine]: (
      <LocalLaundryServiceIcon sx={{ fontSize: "40px" }} />
    ),
    [Amenity.WaterSupply]: <ShowerIcon sx={{ fontSize: "40px" }} />,
    [Amenity.ParkingSpace]: <LocalParkingIcon sx={{ fontSize: "40px" }} />
  };

  function mapAmentiesToProps(): AmenityCardProps[] {
    let amenities = Object.values(Amenity);

    let result: AmenityCardProps[] = [];

    for (let i = 0; i < amenities.length; i++) {
      const element = amenities[i];

      let amenityProps: AmenityCardProps = {
        icon: iconHash[element],
        title: element,
        description: "",
        link: "/properties",
        index: i + 1,
      };
      result.push(amenityProps);
    }

    return result;
  }

  const featuredListings = (allProperties ? allProperties : properties)?.filter(
    (property) => property.isFeatured === true
  );

  const modalWidth = window.innerWidth > 768 ? "40%" : "70%";

  return (
    <Layout>
      <SliderArea />
      {featuredListings && featuredListings.length > 0 && (
        <ProductSlider
          title="Featured Listings"
          properties={featuredListings}
          loading={isLoading}
          featured
        />
      )}
      <ProductSlider
        properties={(allProperties ? allProperties : properties)
          // ?.filter(
          // (property) => property.availability === true)
        }
        loading={isLoading}
      />

      <AboutUs />
      <FeatureArea />
      {/* <CounterUp /> */}
      {/* <TodaySells /> */}
     
      {/* <ExploreNeighbour landmarks={landmarkData}/> */}
      <Aminities aminities={mapAmentiesToProps()} />
      <Testimonials/>
      {/* <Overlay /> */}
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            zIndex: 9999,
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
            width: modalWidth,
            height: "50%",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="Comments Modal"
      > */}
        {/* <SplashScreenIncentives closeModal={closeModal} /> */}
      {/* </Modal> */}
    </Layout>
  );
};

export default HomePage;
