import React, { FormEventHandler, useState } from "react";
import Description, {
  DescriptionErrorState,
} from "../../components/ListingViews/Description";
import Details, {
  DetailsErrorState,
} from "../../components/ListingViews/Details";
import Media from "../../components/ListingViews/Media";
import Location, {
  LocationErrorState,
} from "../../components/ListingViews/Location";
import TabNavAction from "../../components/TabNavAction";
import TabNavigation from "../../components/TabNavigation";
import styles from "./addlv.module.scss";
import Amenities from "../../components/ListingViews/Amenities";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import {
  AmenityWithCount,
  Currency,
  Property,
  PropertyRequest,
} from "../../types/Property";
import { updateProperty } from "../../state/thunks/property";
import { useSWRConfig } from "swr";
import CustomToast from "../../components/CustomToast";
import {
  addProperty,
  addPropertyImage,
  addPropertyProofs,
  editPropertyApi,
  makePropertyFeatured,
} from "../../swr/properties";
import LoadingScreen from "../../components/LoadingScreen";
import { isEmpty } from "../../utils/formUtils";
import ToastContext from "../../contexts/ToastContext";
import { loadImages } from "../../state/reducers/propertySlice";
import { useHistory } from "react-router";
import { SelectChangeEvent } from "@mui/material/Select";
import { getWalletDetails } from "../../swr/wallet";
import { getWalletId } from "../../utils/generalUtils";
import Modal from "react-modal";
import IsFeaturedProperty from "./IsFeaturedProperty";
import { genTransactionRef } from "../../utils/propertyUtils";

export interface AddListingsViewProps {
  isUpdate?: boolean;
  property?: Property;
  close?: () => void;
}

const customStyles = {
  content: {
    top: "54%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // backgroundColor: "var(--ltn__secondary-color)",
    // border: '3px solid rgb(120, 248, 120);',
    borderRadius: "0.7em",
    zIndex: "1",
    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
  },
  overlay: {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "9999",
  },
};

const AddListingsView: React.FC<AddListingsViewProps> = ({
  isUpdate,
  property,
  close,
}) => {
  // Contexts
  const { openError, openSuccess } = React.useContext(ToastContext);
  const history = useHistory();
  const token = localStorage.getItem("token") as string;
  const walletId = getWalletId(token);

  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { response, updateResponse } = useSelector(
    (state: RootState) => state.property
  );

  // Refs
  const formRef = React.createRef<HTMLFormElement>();
  const sectionRef = React.useRef<HTMLDivElement>(null);

  // Scroll
  const executeScroll = () => sectionRef.current?.scrollIntoView();

  // Hooks
  const { mutate } = useSWRConfig();

  // Handlers
  const triggerChange = (eventValue: any) => {
    const customEvent = new CustomEvent("updateProduct", {
      detail: { value: eventValue },
    });
    document.dispatchEvent(customEvent);
  };

  // UseEffect for update mode
  React.useEffect(() => {
    triggerChange(property);
  }, [isUpdate]);

  React.useEffect(() => {
    setConfirmAvailability(false);
    // getWalletDetails(token).then((result) => {
    //   if (result.error) {
    //     let res = result.error;
    //     if (res.response?.status === 404) {
    //       openError("Please create a wallet before listing property");
    //       history.push("/account#liton_tab_1_6");
    //       return;
    //     }
    //     openError("An error occured while fetching wallet details");
    //   }
    // });
  }, []);

  // Types
  type Views = {
    [index: string]: any;
  };

  // Initial Tabbed Views
  let initialViews: Views = {
    description: true,
    media: false,
    location: false,
    details: false,
  };
  let initialUpdateViews: Views = {
    description: true,
    location: false,
    details: false,
  };

  // States
  const [views, setView] = useState<{ [index: string]: any }>(
    isUpdate ? initialUpdateViews : initialViews
  );
  const [viewKey, setViewKey] = useState<string>("description");
  const [activeIndex, setActiveIndex] = useState(0);
  const [errOpen, setErrOpen] = useState<boolean>(false);
  const [successOpen, setSuccessOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [ownershipProof, setOwnershipProof] = useState<File[]>();
  const [amenities, setAmenities] = useState<string[]>([]);
  const [pin, setPin] = useState("");
  const [currency, setCurrency] = useState<Currency>();

  const [isInstallment, setIsInstallment] = useState(false);

  const [isAvailable, setIsAvailable] = useState(false);

  // is Featured Property ?
  const [openIsFeaturedModal, setOpenIsFeaturedModal] = React.useState(false);
  const [addedPropertyId, setAddedPropertyId] = React.useState<number>(0);
  const openIsFeatured = () => {
    setOpenIsFeaturedModal(true);
  };
  const closeIsFeatured = () => {
    setOpenIsFeaturedModal(false);
  };

  const handleCurrencyChange = (c: Currency | undefined) => {
    setCurrency((prev) => c);
  };

  const makePropertyFeaturedCall = async (id: number) => {
    if (!pin) {
      openError("Please enter your pin");
      return;
    }
    if (pin && pin.length !== 4) {
      openError("Please enter correct transaction pin");
      return;
    }
    try {
      setLoading(true);
      closeIsFeatured();
      const data = await makePropertyFeatured({
        token,
        propertyId: id,
        pin,
      });

      if (data && data.succeeded === true) {
        setLoading(false);
        openSuccess(data.message);
        history.push("/account#liton_tab_1_6");
        return;
      } else {
        setLoading(false);
        openError(
          data.message
            ? data.message
            : data.Message
            ? data.Message
            : "An error occured while making payment"
        );
        closeIsFeatured();
        history.push("/");
      }
    } catch (error: any) {
      setLoading(false);
      //@ts-ignore
      openError(
        error.response.message
          ? error.response.message
          : "An error occured while making payment"
      );
      closeIsFeatured();
      history.push("/");
    }
  };
  // Error states for each view
  let initDescriptionErrorState: DescriptionErrorState = {
    title: false,
    description: false,
    price: false,
    duration: false,
    otherPrices: false,
    realEstateName: false,
    downPayment: false,
  };
  let initLocationErrorState: LocationErrorState = {
    address: false,
    country: false,
    state: false,
    city: false,
  };
  let initDetailsErrorState: DetailsErrorState = {
    size: false,
    bedrooms: false,
    bathrooms: false,
    type: false,
    status: false,
    downPayment: false,
  };
  const [descriptionErrorState, setDescriptionErrorState] =
    React.useState<DescriptionErrorState>(initDescriptionErrorState);
  const [locationErrorState, setLocationErrorState] =
    React.useState<LocationErrorState>(initLocationErrorState);
  const [detailsErrorState, setDetailsErrorState] =
    React.useState<DetailsErrorState>(initDetailsErrorState);
  const [confirmAvailability, setConfirmAvailability] = React.useState(false);

  const [proofs, setProofs] = useState([] as File[]);

  const handleAmenityChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setAmenities(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // Function to change view on tab change
  const changeView = (key: string) => {
    setView({
      ...views,
      [viewKey]: false,
      [key.toLowerCase()]: true,
    });
    setViewKey(key.toLowerCase());
  };

  // Steppers
  const stepper = (key: string) => {
    changeView(key);
    setActiveIndex(Object.keys(views).indexOf(key));
    executeScroll();
  };

  //Unhighlight
  const unhighlightDescription = (event: React.ChangeEvent<any>) => {
    setDescriptionErrorState((prevState) => ({
      ...prevState,
      [event.target.name]: false,
    }));
  };
  const unhighlightLocation = (event: React.ChangeEvent<any>) => {
    setLocationErrorState((prevState) => ({
      ...prevState,
      [event.target.name]: false,
    }));
  };
  const unhighlightDetails = (event: React.ChangeEvent<any>) => {
    setDetailsErrorState((prevState) => ({
      ...prevState,
      [event.target.name]: false,
    }));
  };

  const addPropertyProofDoc = async (
    id: number,
    token: string,
    images: File[]
  ) => {
    const { data, error } = await addPropertyProofs(id, token, images);
    console.log(data, "===--data from add doc-000000");

    if (error) {
      openError("There was an error adding the property document");
    }
  };

  // Handlers
  const handleMediaChange = (files: File[], del?: string) => {
    if (del) {
      const filteredFiles = selectedFiles.filter((img) => img.name !== del);
      setSelectedFiles(filteredFiles);
    } else {
      setSelectedFiles((prev) => {
        const updatedFiles = [];
        for (const newFile of files) {
          // Check if the image already exists in the selectedFiles array
          const isExisting = prev.some(
            (prevFile) => prevFile.name === newFile.name
          );
          if (isExisting) {
            openError(`Image ${newFile.name} already exists.`);
          } else {
            // Add the new file to the array if it doesn't already exist
            updatedFiles.push(newFile);
          }
        }
        // Concatenate the new files with the existing ones
        return [...prev, ...updatedFiles];
      });
    }
  };

  const handleProofChange = (files?: File[]) => {
    setOwnershipProof(files);
  };

  // Form validator
  const validateForm = (fdata: FormData): "passed" | "failed" => {
    let result: "passed" | "failed" = "passed";

    // Check if media and or proof of ownership has been added

    if (selectedFiles.length === 0 || !ownershipProof) {
      result = "failed";
    }

    // Check if any field is empty
    for (const pairs of fdata.entries()) {
      //console.log(pairs);
      if (isEmpty(pairs[1].toString())) {
        if (
          Object.prototype.hasOwnProperty.call(descriptionErrorState, pairs[0])
        ) {
          setDescriptionErrorState((prevState) => ({
            ...prevState,
            [pairs[0]]: true,
          }));
          result = "failed";
        }
        if (
          Object.prototype.hasOwnProperty.call(locationErrorState, pairs[0])
        ) {
          setLocationErrorState((prevState) => ({
            ...prevState,
            [pairs[0]]: true,
          }));
          result = "failed";
        }
        if (Object.prototype.hasOwnProperty.call(detailsErrorState, pairs[0])) {
          setDetailsErrorState((prevState) => ({
            ...prevState,
            [pairs[0]]: true,
          }));
          result = "failed";
        }
      }
    }
    return result;
  };

  // On Submit for form submission
  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!confirmAvailability) {
      openError(
        "Please confirm you will advice on property availability when appropriate"
      );
      return;
    }
    if (formRef.current) {
      const fdata = new FormData(formRef.current);
      const fAmenities = amenities.map((amenityValue) => {
        return {
          amenity: amenityValue,
          number: 1,
        };
      });
      //if (validateForm(fdata) === 'passed'){

      // Handling numbers and special types
      let price = fdata.get("price");
      let usedPrice = price ? price.toString().replace(/,/g, "") : "0";
      let otherPrices = fdata.get("otherPrices");
      let usedOtherPrices = otherPrices
        ? otherPrices.toString().replace(/,/g, "")
        : "0";
      let size = fdata.get("size");
      let usedSize = size ? size.toString() : "0";
      let longitude = fdata.get("longitude");
      let usedLongitude = longitude ? longitude.toString() : "0";
      let latitude = fdata.get("latitude");
      let usedLatitude = latitude ? latitude.toString() : "0";

      // The Request
      let preRequest: PropertyRequest | any = {
        title: fdata.get("title")?.toString(),
        description: fdata.get("description")?.toString(),
        price: Number.parseFloat(usedPrice),
        otherPrices: Number.parseFloat(usedOtherPrices),
        realEstateName: fdata.get("realEstateName")?.toString(),
        totalPrice:
          Number.parseFloat(usedPrice) + Number.parseFloat(usedOtherPrices),
        status: fdata.get("status")?.toString().replace(/\s/g, ""),
        type:
          fdata.get("type")?.toString() === "3"
            ? "Mortgage"
            : fdata.get("type")?.toString(),
        size: Number.parseFloat(usedSize),
        address: fdata.get("address")?.toString(),
        state: fdata.get("state")?.toString(),
        city: fdata.get("city")?.toString(),
        //neighbourhood: fdata.get('neighbourhood')?.toString(),
        longitude: Number.parseFloat(usedLongitude),
        latitude: Number.parseFloat(usedLatitude),
        zip: fdata.get("zip")?.toString(),
        bedrooms: fdata.get("bedrooms")?.toString(),
        bathrooms: fdata.get("bathrooms")?.toString(),
        availability: isAvailable,
        // availability: fdata.get("availability")?.toString() === "yes",
        isInstallmental: isInstallment,
        isCompleted:
          typeof fdata.get("completion") === "boolean"
            ? fdata.get("completion")
            : false,
        paymentDuration: fdata.get("repayment"),
        amenities: fAmenities,
        attachments: [],
        isFeatured: fdata.get("isFeatured")?.toString() === "yes",
        duration: fdata.get("duration")?.toString(),
        downPayment: fdata.get("downpayment")?.toString(),
        parkingLot: "No",
        dateAdded:
          isUpdate && property ? property.dateAdded : new Date().toISOString(),
        currency
      };
      let token = localStorage.getItem("token") || undefined;

      const request = new FormData();
      for (var key in preRequest) {
        request.append(key, preRequest[key]);
      }

      if (isUpdate && property?.id) {
        const response = await editPropertyApi(
          preRequest,
          token as string,
          property.id
        );
        if (response.status !== 200) {
          setLoading(false);
          openError(
            response.message
              ? response.message
              : response.Message
              ? response.Message
              : "an error occurred"
          );
          close && close();
        } else {
          if (response.data) {
            setLoading(false);
            openSuccess("Update Successful");
            close && close();
          } else {
            setLoading(false);
            openError(
              response.message
                ? response.message
                : response.Message
                ? response.Message
                : "an error occurred"
            );
            close && close();
          }
        }
        return;
      } else {
        setLoading(true);
        const { data, error } = await addProperty({
          request: preRequest,
          token,
        });
        if (data) {
          // Check If there are images
          setSuccessOpen(true);
          openSuccess("Property submitted, awaiting admin approval");

          if (ownershipProof && ownershipProof.length > 0 && data.id) {
            await addPropertyProofDoc(data.id!, token!, ownershipProof);
          }

          if (selectedFiles.length > 0 && data.id) {
            const uploadResult = await addPropertyImage({
              id: data.id || 0,
              files: selectedFiles,
              token: token,
            });

            if (uploadResult.data) {
              // Trigger revalidation
              mutate(`/api/properties?PageNumber=1&PageSize=1000000`);
              setLoading(false);
              // openSuccess("Property submitted, awaiting admin approval");
              setSuccessOpen(true);
              // history.push("/properties");
            }

            if (uploadResult.error) {
              mutate(`/api/properties?PageNumber=1&PageSize=1000000`);
              setLoading(false);
              setSuccessOpen(true);
              // openSuccess("Property submitted, awaiting admin approval");
              openError("Could not upload images");
            }
          } else {
            // Trigger revalidation
            mutate(`/api/properties?PageNumber=1&PageSize=1000000`);
            setLoading(false);
            // openSuccess("Property submitted, awaiting admin approval");
            setSuccessOpen(true);
            // history.push("/properties");
          }

          openIsFeatured();
          setAddedPropertyId(data.id!);
        }
        if (error) {
          setLoading(false);
          setErrOpen(true);
        }
        // dispatch(addProperty({ request: preRequest, token }));
      }
      // }
      // else{
      //     openError('Please fill in all required fields');
      // }
    }
  };

  return (
    <>
      {loading && <LoadingScreen />}
      {/* <CustomToast
        errOpen={errOpen}
        successOpen={successOpen}
        onErrClose={() => setErrOpen(false)}
        onSuccessClose={() => setSuccessOpen(false)}
        errMessage={"An error occurred while adding your property"}
        successMessage={"Property submitted, awaiting admin approval"}
      /> */}
      <div className="ltn__appointment-area pt-115--- pb-120" ref={sectionRef}>
        <div className={`container`}>
          <div className="row">
            <div className="col-lg-12">
              <form
                noValidate
                onSubmit={(e) => e.preventDefault()}
                ref={formRef}
              >
                {/** Tabbed Nav */}
                <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
                  {!isUpdate && (
                    <TabNavigation onChange={changeView} index={activeIndex}>
                      <TabNavAction label="Description" />
                      <TabNavAction label="Media" />
                      <TabNavAction label="Location" />
                      <TabNavAction label="Details" />
                    </TabNavigation>
                  )}
                  {isUpdate && (
                    <TabNavigation onChange={changeView} index={activeIndex}>
                      <TabNavAction label="Description" />
                      <TabNavAction label="Location" />
                      <TabNavAction label="Details" />
                    </TabNavigation>
                  )}
                </div>

                {/** View */}
                <div className="tab-content">
                  <div
                    style={{ display: views.description ? "block" : "none" }}
                  >
                    <Description
                      onNextStep={stepper}
                      nextKey={isUpdate ? "location" : "media"}
                      errorState={descriptionErrorState}
                      unhighlight={unhighlightDescription}
                      isInstallment={isInstallment}
                      setIsInstallment={setIsInstallment}
                      currency={currency}
                      handleCurrencyChange={handleCurrencyChange}
                    />
                  </div>
                  <div style={{ display: views.media ? "block" : "none" }}>
                    <Media
                      onNextStep={stepper}
                      nextKey="location"
                      onPrevStep={stepper}
                      prevKey="description"
                      onMediaChange={handleMediaChange}
                      selectedMedia={selectedFiles}
                      onProofChange={handleProofChange}
                      ownershipProof={ownershipProof}
                    />
                  </div>
                  <div style={{ display: views.location ? "block" : "none" }}>
                    <Location
                      onNextStep={stepper}
                      nextKey="details"
                      onPrevStep={stepper}
                      prevKey={isUpdate ? "description" : "media"}
                      errorState={locationErrorState}
                      unhighlight={unhighlightLocation}
                    />
                  </div>
                  <div style={{ display: views.details ? "block" : "none" }}>
                    <Details
                      onSubmit={onSubmit}
                      onPrevStep={stepper}
                      prevKey="location"
                      errorState={detailsErrorState}
                      unhighlight={unhighlightDetails}
                      handleAmenityChange={handleAmenityChange}
                      amenities={amenities}
                      confirmAvailability={confirmAvailability}
                      setConfirmAvailability={setConfirmAvailability}
                      isAvailable={isAvailable}
                      setIsAvailable={setIsAvailable}
                    />
                  </div>
                </div>
                <Modal
                  isOpen={openIsFeaturedModal}
                  onRequestClose={closeIsFeatured}
                  style={{
                    content: {
                      top: "54%",
                      left: "50%",
                      right: "auto",
                      bottom: "auto",
                      marginRight: "-50%",
                      transform: "translate(-50%, -50%)",
                      // backgroundColor: "var(--ltn__secondary-color)",
                      // border: '3px solid rgb(120, 248, 120);',
                      borderRadius: "0.7em",
                      zIndex: "9999",
                      boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
                    },
                    overlay: {
                      position: "fixed",
                      top: "0",
                      left: "0",
                      right: "0",
                      bottom: "0",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: "9999",
                    },
                  }}
                  contentLabel="isFeatured Modal"
                  // appElement={document.getElementById('root') as HTMLElement}
                >
                  <IsFeaturedProperty
                    closeModal={closeIsFeatured}
                    makePropertyFeaturedCall={makePropertyFeaturedCall}
                    id={addedPropertyId}
                    pin={pin}
                    setPin={setPin}
                  />
                </Modal>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddListingsView;
