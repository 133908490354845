import { AxiosError, AxiosResponse } from "axios";
import { SolarFinanceRequest } from "../types/Solar";
import api from "../axios";

export const applySolarFinance = (req: SolarFinanceRequest, token: string) => {
  const willPostSolar = new Promise<any>((resolve) => {
    api
      .post("/api/Solar/Apply", req, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });
  return willPostSolar;
};
