import { AxiosError } from "axios";
import api from "../axios";
import { ApplySmartHomesReq } from "../types/samertHomes";

export const applySmartHomes = (request: ApplySmartHomesReq, token: string) => {
    const willApply: Promise<any> = new Promise((resolve) => {
        api
            .post("/api/SmartHome/Apply", request, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((err: AxiosError | any) => {
                resolve({ error: err });
            });
    });

    return willApply;
};