import Axios, { AxiosError, AxiosResponse } from "axios";
import useSwr from "swr";
import {
  AddPropertyRequest,
  UpdatePropertyRequest,
} from "../state/thunks/property";
import {
  ApplyMortgageReq,
  ApplyPensionsReq,
  Attachment,
  EditProperty,
  ImageBlobFormat,
  MakePropertyFeaturedReq,
  MakePropertyFeaturedRes,
  Property,
  PropertyPayment,
  PropertyPaymentResponse,
} from "../types/Property";
import {
  RatingAddResponse,
  RatingRequest,
  RatingResponse,
} from "../types/Rating";
import { ValidationErrors } from "./wallet";
import api from "../axios";
import {
  ConfirmMortgageRequest,
  MarkPensionMortgage,
  MarkPensionMortgageResult, 
} from "../types/Pension";

const baseUrl = process.env.REACT_APP_BASE_URL || "https://api.homebuddy.ng";
// const baseUrl = process.env.REACT_APP_BASE_URL || "https://api.homebuddy.ng";
const token = localStorage.getItem("token");

interface UseProductResult {
  product?: Property;
  isLoading?: boolean;
  isError?: any;
}

export interface GetComments {
  id: number;
  userId: string;
  user: string;
  propertiesId: number;
  properties: any;
  rating: number;
  comment: string;
}

export interface GetCommentByRating {
  succeeded: boolean;
  message: string;
  errors: any;
  data: [GetComments];
}

export const useProduct = (id: string | number) => {
  const fetcher = (url: string) =>
    Axios.get(baseUrl + url).then(
      (response: AxiosResponse<Property>) => response.data
    );
  const { data, error } = useSwr(`/api/properties/${id}`, fetcher);
  return {
    product: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export interface UsePropertiesData {
  properties?: Property[];
  isLoading?: boolean;
  isError?: any;
  pagination?: any;
}

export const useProperties = (
  token?: string,
  pageNumber?: string | number,
  pageSize?: string | number
): UsePropertiesData => {
  let pageInfo = "";

  if (pageNumber && pageSize) {
    pageInfo = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
  }

  const fetcher = (url: string) =>
    Axios.get(baseUrl + url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.data);

  const { data, error } = useSwr(`/api/properties${pageInfo}`, fetcher);
  // console.log(data, 'it is data');

  return {
    properties: data && data.item1 && data.item1,
    isLoading: !error && !data,
    isError: error,
    pagination: data && data.item2 && data.item2,
  };
};

export const getProperties = async (PageNumber?: number, PageSize?: number) => {
  try {
    const response = await Axios.get(
      `${baseUrl}/api/Properties?PageNumber=${
        PageNumber && PageNumber
      }&PageSize=${PageSize && PageSize}`
    );

    if (response) return response.data;
  } catch (err) {
    // @ts-ignore
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }
    return error.response.data;
  }
};

export const getPropertiesByAgentId = (
  req: { agentId: string; pageSize: number; pageNumber: number },
  token: string
) => {
  const listerProperties = new Promise<any>((resolve) => {
    api
      .get(
        `/api/Properties/properties/${req.agentId}?PageNumber=${req.pageNumber}&PageSize=${req.pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });
  return listerProperties;
};

interface UpdatePropertyResult {
  data?: any;
  error?: AxiosError;
}

export const updateProperty = ({
  request,
  token,
  id,
}: UpdatePropertyRequest) => {
  const willUpdate: Promise<UpdatePropertyResult> = new Promise((resolve) => {
    api
      .put("/api/properties/" + id, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          //"content-type": "multipart/form-data"
        },
      })
      .then((response) => {
        resolve({ data: true });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return willUpdate;
};

interface DeletePropertyRequest {
  id: string | number;
  token?: string;
}

export const deleteProperty = ({ token, id }: DeletePropertyRequest) => {
  const willDelete = new Promise<UpdatePropertyResult>((resolve) => {
    api
      .post(
        "/api/Properties/DeleteProperty/" + id,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        resolve({ data: true });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return willDelete;
};

interface AddPropertyResult {
  data?: Property;
  error?: any;
}

export const addProperty = ({ request, token }: AddPropertyRequest) => {
  const willAdd = new Promise<AddPropertyResult>((resolve) => {
    api
      .post("/api/Properties/CreateProperty", request, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse<Property>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return willAdd;
};

export const saveProperty = ({
  id,
  token,
}: {
  id: string | undefined;
  token: string;
}) => {
  const saveProperty = new Promise<AddPropertyResult>((resolve) => {
    api
      .post(
        `/api/Properties/SaveProperty?id=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return saveProperty;
};

export const getSavedProperties = (
  req: { PageSize: number; PageNumber: number },
  token: string
) => {
  const savedProperties = new Promise<any>((resolve) => {
    api
      .get(
        `/api/Properties/GetSavedProperties?PageNumber=${req.PageNumber}&PageSize=${req.PageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return savedProperties;
};

export interface AddPropertyImageRequest {
  id: number;
  files: File[] | any;
  token?: string;
  skipAppend?: boolean;
}
interface AddPropertyImageResult {
  data?: any;
  error?: any;
}

export const addPropertyImage = ({
  id,
  files,
  token,
  skipAppend,
}: AddPropertyImageRequest) => {
  if (skipAppend === true) {
    let fdata = new FormData();
    for (let i = 0; i < files.length; i++) {
      fdata.append("file", files[i]);
    }
    const willAddImage = new Promise<AddPropertyImageResult>((resolve) => {
      Axios.post(baseUrl + `/api/properties/image/${id}`, fdata, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response: AxiosResponse<any>) => {
          resolve({ data: true });
        })
        .catch((err: AxiosError | any) => {
          resolve({ error: err });
        });
    });

    return willAddImage;
  }

  let fdata = new FormData();
  for (let i = 0; i < files.length; i++) {
    fdata.append("file", files[i], files[i].name);
  }

  const willAddImage = new Promise<AddPropertyImageResult>((resolve) => {
    Axios.post(baseUrl + `/api/properties/image/${id}`, fdata, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response: AxiosResponse<any>) => {
        resolve({ data: true });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return willAddImage;
};

export const deletePropertyImages = (
  attachments: Attachment[],
  token?: string
) => {
  const willDeleteImage = new Promise<AddPropertyImageResult>(
    async (resolve) => {
      let deleteCalls = attachments.map(async (a) => {
        let result = await Axios.post(
          baseUrl + `/api/Properties/delete_attachment/${a.id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return result;
      });

      await Promise.all(deleteCalls);
      resolve({ data: true });
    }
  );

  return willDeleteImage;
};

export const DeletePropertyImages = async (request: {
  token?: string;
  id: Number | undefined;
}): Promise<any> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${request.token}`,
      },
    };
    const response = await api.post(
      `/api/Properties/delete_attachment/${request.id}`,
      {},
      config
    );

    return response;
  } catch (err) {
    console.log(err, "user service error");
    // @ts-ignore
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }
    console.log(error.response.data, "data");

    return error.response.data;
  }
};

interface SearchPropertyResult {
  data?: Property[];
  error?: any;
}

// export const searchProperty = (queryParameters: string): Promise<SearchPropertyResult> =>{
//     const willSearch = new Promise<SearchPropertyResult>((resolve)=>{
//         Axios.get(baseUrl + queryParameters)
//         .then((response: AxiosResponse<Property[]>)=>{
//             resolve({ data: response.data })
//         })
//         .catch((err: AxiosError | any)=>{
//             resolve({ error: err });
//         })
//     })

//     return willSearch;
// }

export const searchProperty = async (
  Type?: string,
  State?: string,
  City?: string,
  Status?: string
): Promise<Property[]> => {
  let url = baseUrl + "/api/Properties/search";
  const queries = [];
  if (Type && Number(Type) >= 0) {
    queries.push("Type");
  }
  if (State && Number(State) >= 0) {
    queries.push("State");
  }
  if (City && City.length > 0) {
    queries.push("City");
  }
  if (Status && Number(Status) >= 0) {
    queries.push("Status");
  }
  if (queries.length > 0) {
    url = url + "?";
    for (let i = 0; i < queries.length; i++) {
      let query = queries[i];
      url =
        url +
        `${query}=${
          query === "Type"
            ? Type
            : query === "State"
            ? State
            : query === "City"
            ? City
            : Status
        }` +
        "&";
    }
  }
  const { data } = await Axios.get(url.slice(0, -1), {
    headers: {
      Authorization: `Bearer ${token && token}`,
    },
  });
  return data;
};

export const usePropertySearch = (search?: string, token?: string) => {
  const fetcher = (url: string) =>
    Axios.get(baseUrl + url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.data);

  const { data, error } = useSwr(`/api/properties/search${search}`, fetcher);
  return {
    properties: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const getComments = async (
  PropertiesId: number | undefined,
  token?: string,
  PageNumber?: number | null,
  PageSize?: number | null,
  IncludeCount?: boolean
): Promise<GetComments> => {
  // const url = new URL(baseUrl + '/api/Ratings/get-rating-by-property')
  const config = {
    headers: {
      Authorization: `Bearer ${token && token}`,
    },
  };

  let url =
    baseUrl +
    `/api/Ratings/get-rating-by-property?PropertiesId=${PropertiesId}`;
  // PropertiesId && url.searchParams.set('PropertiesId', PropertiesId.toString())
  // PageNumber && url.searchParams.set('PageNumber', PageNumber.toString())
  // PageSize && url.searchParams.set('PageSize', PageSize.toString())

  if (PageNumber && PageSize) {
    url += `&PageNumber=${PageNumber}&PageSize=${PageSize}`;
  }

  const { data } = await Axios.get(url, config);

  return data;
};

export const payForProperty = async (
  request: PropertyPayment,
  token: string,
  pin: string
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token && token}`,
      },
    };
    const { data } = await api.post(
      `/api/Payment/WalletPayment?pin=${pin}`,
      request,
      config
    );
    return data;
  } catch (err) {
    // @ts-ignore
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return error.response.data;
  }
};

export const makePropertyFeatured = async (
  req: MakePropertyFeaturedReq
): Promise<MakePropertyFeaturedRes | any> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${req.token}`,
      },
    };
    const response = await api.post(
      `/api/Payment/FeaturedPropertyPayment?propertyId=${req.propertyId}&pin=${req.pin}`,
      {},
      config
    );
    return response.data;
  } catch (err) {
    // @ts-ignore
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return error.response.data;
  }
};

export const editPropertyApi = async (
  req: EditProperty | any,
  token: string,
  id: number | string
): Promise<any> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await api.post(
      `/api/Properties/Edit-Property?id=${id}`,
      req,
      config
    );

    return response;
  } catch (err) {
    // @ts-ignore
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return error.response.data;
  }
};

export const ApplyMortgageApi = async (
  req: ApplyMortgageReq,
  token: string
): Promise<any> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await api.post(
      `/api/Mortgage/ApplyForMortgage`,
      req,
      config
    );

    return response;
  } catch (err) {
    // @ts-ignore
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return error.response.data;
  }
};

export const getPersonalizedListingsApi = async (
  req: { PageNumber: number; PageSize: number },
  token: string
): Promise<any> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await api.get(
      `/api/Properties/GetPropertiesByOwner?PageNumber=${req.PageNumber}&PageSize=${req.PageSize}`,
      config
    );

    return response.data;
  } catch (err) {
    // @ts-ignore
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return error.response.data;
  }
};

export const getMortgageListingsApi = async (
  req: { PageNumber: number; PageSize: number },
  token: string
): Promise<any> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await Axios.get(
      `${baseUrl}/api/Properties/GetMortgageProperties?PageNumber=${req.PageNumber}&PageSize=${req.PageSize}`,
      config
    );

    return response.data;
  } catch (err) {
    // @ts-ignore
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return error.response.data;
  }
};

interface ApplyPensionsResult {
  data?: any;
  error?: any;
}

export const ApplyAccessPensions = (req: ApplyPensionsReq, token: string) => {
  const applyAccessPensions = new Promise<ApplyPensionsResult>((resolve) => {
    api
      .post("/api/Mortgage/ApplyForPension", req, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return applyAccessPensions;
};

export const getPensionApplicationsApi = async (
  req: { PageNumber: number; PageSize: number },
  token: string,
  status?: number
) => {
  const getAccessPensions = new Promise<ApplyPensionsResult>((resolve) => {
    api
      .get(
        `/api/Mortgage/ViewPensionApplications?PageNumber=${req.PageNumber}&PageSize=${req.PageSize}&status=${status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return getAccessPensions;
};

export const getMortgageApplicationsApi = async (
  req: { PageNumber: number; PageSize: number },
  token: string,
  status?: number
) => {
  const getAccessPensions = new Promise<ApplyPensionsResult>((resolve) => {
    api
      .get(
        `/api/Mortgage/ViewMortgageApplications?PageNumber=${req.PageNumber}&PageSize=${req.PageSize}&status=${status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return getAccessPensions;
};

export const getPensionApplicatApi = async (id: string, token: string) => {
  const getAccessPension = new Promise<ApplyPensionsResult>((resolve) => {
    api
      .post(
        `/api/Mortgage/GetPensionById?id=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return getAccessPension;
};

export const getMortgageApplicantApi = async (id: string, token: string) => {
  const getMortgageApplicant = new Promise<ApplyPensionsResult>((resolve) => {
    api
      .post(
        `/api/Mortgage/GetMortgageById?id=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return getMortgageApplicant;
};

export const markPensionApi = async (
  id: string,
  token: string,
  attended: boolean
) => {
  const markMortgageApplication = new Promise<MarkPensionMortgageResult>(
    (resolve) => {
      api
        .post(
          `/api/Mortgage/AttendToPension?Id=${id}&AttendedTo=${attended}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response: AxiosResponse<MarkPensionMortgage>) => {
          resolve({ data: response.data });
        })
        .catch((err: AxiosError | any) => {
          resolve({ error: err });
        });
    }
  );

  return markMortgageApplication;
};

export const markMortgageApi = async (
  id: string,
  token: string,
  attended: boolean
) => {
  const markMortgageApplication = new Promise<MarkPensionMortgageResult>(
    (resolve) => {
      api
        .post(
          `/api/Mortgage/AttendToMortgage?id=${id}&AttendedTo=${attended}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response: AxiosResponse<MarkPensionMortgage>) => {
          resolve({ data: response.data });
        })
        .catch((err: AxiosError | any) => {
          resolve({ error: err });
        });
    }
  );

  return markMortgageApplication;
};

export const confirmMortgageApi = async (
  id: string,
  token: string,
  req: ConfirmMortgageRequest
) => {
  const markMortgageApplication = new Promise<MarkPensionMortgageResult>(
    (resolve) => {
      api
        .post(`/api/Mortgage/ConfirmMortgage/${id}`, req, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response: AxiosResponse<MarkPensionMortgage>) => {
          resolve({ data: response.data });
        })
        .catch((err: AxiosError | any) => {
          resolve({ error: err });
        });
    }
  );

  return markMortgageApplication;
};

export const addPropertyProofs = (
  id: number,
  token: string,
  images: File[]
) => {
  console.log(images, "===prooofs===", id, "id");

  let fdata = new FormData();
  for (let i = 0; i < images.length; i++) {
    fdata.append("file", images[i]);
  }
  const savedProperties = new Promise<any>((resolve) => {
    api
      .post(`/api/Properties/ProofAttachment/${id}`, fdata, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: AxiosResponse<any>) => {
        console.log(response.data, "add proofs");

        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        console.log(err, "from api proofs");

        resolve({ error: err });
      });
  });

  return savedProperties;
};
