import { useContext, useEffect, useState } from "react";
import { MortageApplicant } from "../../../types/Property";
import Avatar from "../../../assets/avatar.jpeg";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import LoadingContext from "../../../contexts/LoadingContext";
import ToastContext from "../../../contexts/ToastContext";
import {
  confirmMortgageApi,
  getMortgageApplicantApi,
  markMortgageApi,
} from "../../../swr/properties";
import styles from "../viewmortgageapplicants.module.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModalLayout from "../../../components/ModalLayout";
import MarkOptions from "../../../components/Mortgage/MarkOptions";
import MoreInformation from "../../../components/Mortgage/MoreInformation";
import RejectIcon from "../../../assets/reject.svg";
import AcceptIcon from "../../../assets/accept.svg";

const ViewMortgageApplicant = () => {
  const params: { id: string } = useParams();
  const history = useHistory();
  const [applicant, setApplicant] = useState<MortageApplicant>(
    {} as MortageApplicant
  );
  const [reason, setReason] = useState("");
  const markOptions = [
    { icon: RejectIcon, text: "Reject Application" },
    { icon: AcceptIcon, text: "Accept Application" },
  ];
  const [status, setStatus] = useState<boolean | undefined>(undefined);
  const [modalsOpen, setModalsOpen] = useState({
    markOptions: false,
    comments: false,
  });
  const toggleMarkOptions = () => {
    setModalsOpen((prev) => ({ ...prev, markOptions: !prev.markOptions }));
  };
  const toggleComments = () => {
    setModalsOpen((prev) => ({ ...prev, comments: !prev.comments }));
  };
  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const token = localStorage.getItem("token") as string;

  const handleReasonChange = (text: string) => {
    setReason((prev) => text);
  };

  const handleOptionsClick = (selection: string) => {
    const accepted = selection === "Accept Application";
    if (accepted) {
      setStatus((prev) => true);
      toggleMarkOptions();
      markApplication(params.id, token, accepted);
    } else {
      setStatus((prev) => false);
      toggleMarkOptions();
      toggleComments();
    }
  };

  const getMortgageApplicantById = async (applicantId: string) => {
    setLoading(true);
    const { data, error } = await getMortgageApplicantApi(applicantId, token);

    if (data && data.succeeded && data.data) {
      setApplicant(data.data);
      setLoading(false);
      return;
    }

    if (error) {
      setLoading(false);
      openError("Could not load resource");
      return;
    }
    setLoading(false);
    openError("Could not load resource");
    return;
  };

  const markApplication = async (
    applicantId: string,
    tokenA: string,
    approve: boolean,
    reason?: string
  ) => {
    setLoading(true);
    const { data, error } = await confirmMortgageApi(applicantId, tokenA, {
      approve,
      reason,
    });

    if (data && data.succeeded) {
      openSuccess("Successfully marked as read");
      setLoading(false);
      return;
    }

    if (error) {
      setLoading(false);
      openError("Could not load resource");
      return;
    }
    setLoading(false);
    openError("Could not load resource");
    return;
  };

  const callMarkApp = () => {
    if (!reason) {
      openError("Please include additional information");
      return;
    }
    toggleComments()
    markApplication(params.id, token, status as boolean, reason);
  };

  useEffect(() => {
    getMortgageApplicantById(params.id);
  }, []);

  const imgUrl =
    applicant && applicant.user && applicant.user.imageUrl
      ? applicant.user.imageUrl
      : Avatar;

  function formatDate(dateString: Date): string {
    // Parse the date string into a Date object
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    // Function to get the ordinal suffix for a given day
    function getOrdinalSuffix(day: number): string {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    // Concatenate the day with its ordinal suffix
    const formattedDate = `${day}${getOrdinalSuffix(
      day
    ).toUpperCase()} ${month.toUpperCase()} ${year}`;
    return formattedDate;
  }

  function formatAmount(
    number: number,
    decimals: number = 2,
    locale: string = "en-NG"
  ): string {
    // Check if the number is a valid finite number
    if (!isFinite(number)) {
      throw new Error("Invalid number");
    }

    // Use toFixed to set the number of decimals
    const formattedNumber = number.toFixed(decimals);

    // Use toLocaleString to add commas for thousands separator
    const formattedAmount = parseFloat(formattedNumber).toLocaleString(locale);

    return formattedAmount;
  }

  const returnSalaryStructure = (sal: number) => {
    if (sal === 1) {
      return "Salary Earner";
    } else {
      return "Self Employed";
    }
  };

  return (
    <div className={styles["profile-container"]}>
      <div className={styles["profile-back"]}>
        <div
          className={styles["profile-back-cont"]}
          onClick={() => history.push("/viewmortgage")}
          role="button"
        >
          <ArrowBackIcon />
        </div>
      </div>

      <div className={styles["profile-main"]}>
        <div className={styles["profile-info-main"]}>
          <div className={styles["profile-info-1"]}>
            <div className={styles["profile-info-1-img"]}>
              <img src={imgUrl} alt="Applicant's image" />
            </div>

            <div className={styles["profile-info-1-info"]}>
              <p>Application</p>

              <div className={styles["profile-info-1-info-each"]}>
                <div>First Name</div>
                <span>
                  {applicant && applicant.user && applicant.user.firstName}
                </span>
              </div>

              <div className={styles["profile-info-1-info-each"]}>
                <div>Last Name</div>
                <span>
                  {applicant && applicant.user && applicant.user.lastName}
                </span>
              </div>

              <div className={styles["profile-info-1-info-each"]}>
                <div>Salary Structure</div>
                <span>
                  {returnSalaryStructure(
                    applicant && applicant.salaryStructure
                  )}
                </span>
              </div>

              <div className={styles["profile-info-1-info-each"]}>
                <div>Monthly Income</div>
                <span>
                  ₦
                  {applicant &&
                    applicant.monthlyIncome &&
                    formatAmount(applicant.monthlyIncome)}
                </span>
              </div>

              <div className={styles["profile-info-1-info-each"]}>
                <div>Annual Income</div>
                <span>
                  ₦
                  {applicant &&
                    applicant.annualNetIncome &&
                    formatAmount(applicant.annualNetIncome)}
                </span>
              </div>
              <div className={styles["profile-info-1-info-each"]}>
                <div>Age</div>
                <span>{applicant && applicant.age}</span>
              </div>

              <div className={styles["profile-info-1-info-each"]}>
                <div>Created At</div>
                <span>
                  {applicant &&
                    applicant.createdAt &&
                    formatDate(applicant.createdAt)}
                </span>
              </div>

              <div
                className={styles["mark-treated"]}
                role="button"
                onClick={toggleMarkOptions}
              >
                <span>Mark As Treated</span>
              </div>
            </div>
          </div>
          <div className={styles["profile-info-2"]}>
            <div className={styles["profile-info-2A"]}>
              <p>Contd'</p>
              <div className={styles.line}></div>
              <div className={styles.contact}>
                <ul>
                  <li>{applicant && applicant.user && applicant.user.email}</li>
                  <li>
                    {applicant && applicant.user && applicant.user.phoneNumber}
                  </li>
                  {/* <li>{applicant && applicant.user && applicant.user.address}</li> */}
                  {/* <li>
                  {applicant &&
                    applicant.state &&
                    statesForSearch[applicant.state] &&
                    statesForSearch[applicant.state].toUpperCase()}
                </li> */}
                </ul>
              </div>
            </div>
            <div className={styles["profile-info-2B"]}>
              <p>Other Information</p>
              <div className={styles.line}></div>

              {applicant && applicant.repaymentPeriod && (
                <div className={styles.each}>
                  <span>Repayment Period</span>
                  <div>{applicant && applicant.repaymentPeriod} years</div>
                </div>
              )}

              {applicant &&
              applicant.downPaymentFromPension === true &&
              applicant.rsaPin ? (
                <div className={styles.each}>
                  <span>RSA PIN</span>
                  <div>{applicant && applicant.rsaPin}</div>
                </div>
              ) : (
                <div className={styles.each}>
                  <div>{`${
                    applicant && applicant.user && applicant.user.firstName
                  } has no RSA PIN`}</div>
                  <div className={styles.norsa}></div>
                </div>
              )}

              {applicant && applicant.salaryStructure === 1 ? (
                <div className={styles.each}>
                  {/* <span></span> */}
                  <div>
                    {applicant && applicant.confirmedStaff === true
                      ? "Applicant is a confirmed staff"
                      : "Applicant is not a confirmed staff"}
                  </div>
                  {applicant && applicant.confirmedStaff === true ? (
                    <div className={styles.hasrsa}></div>
                  ) : (
                    <div className={styles.notrsa}></div>
                  )}
                </div>
              ) : (
                <div className={styles.each}>
                  {/* <div className={styles.norsa}></div> */}

                  <div>
                    {applicant && applicant.threeYearsEmployed === true
                      ? "Applicant has been in business for 3 years"
                      : "Applicant has not been in business for 3 years"}
                  </div>
                  {applicant && applicant.threeYearsEmployed === true ? (
                    <div className={styles.hasrsa}></div>
                  ) : (
                    <div className={styles.notrsa}></div>
                  )}
                </div>
              )}

              <div className={styles.each}>
                <div>
                  {applicant && applicant.downPaymentFromPension === true
                    ? "Applicant will pay from pension"
                    : "Applicant will not pay from pension"}
                </div>

                {applicant && applicant.downPaymentFromPension === true ? (
                  <div className={styles.hasrsa}></div>
                ) : (
                  <div className={styles.notrsa}></div>
                )}
              </div>

              <div className={styles.each}>
                <div>
                  {applicant && applicant.hasAccessPension === true
                    ? "Applicant uses access pension"
                    : "Applicant does not use access pension"}
                </div>

                {applicant && applicant.hasAccessPension === true ? (
                  <div className={styles.hasrsa}></div>
                ) : (
                  <div className={styles.notrsa}></div>
                )}
              </div>

              <div className={styles.each}>
                <span>Property Name</span>
                <div>
                  {applicant &&
                    applicant.properties &&
                    applicant.properties.title}
                </div>
              </div>

              <div className={styles.each}>
                <span>Property Price</span>
                <div>
                  {applicant &&
                  applicant.properties &&
                  applicant.properties.totalPrice
                    ? `₦${
                        applicant.properties.totalPrice &&
                        formatAmount(applicant.properties.totalPrice)
                      }`
                    : applicant.properties?.price
                    ? `₦${
                        applicant.properties.price &&
                        formatAmount(applicant.properties.price)
                      }`
                    : ""}
                </div>
              </div>

              {applicant && applicant.downPaymentFromPension === true ? (
                <div className={styles.each}>
                  <span>RSA Balance</span>
                  <div>
                    ₦
                    {applicant &&
                      applicant.pensionBalance &&
                      formatAmount(applicant.pensionBalance)}
                  </div>
                </div>
              ) : (
                <div className={styles.each}>
                  <span>Down-Payment</span>
                  <div>
                    ₦
                    {applicant &&
                      applicant.downPayment &&
                      formatAmount(applicant.downPayment)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalLayout modalOpen={modalsOpen.markOptions}>
        <MarkOptions
          options={markOptions}
          click={handleOptionsClick}
          closeFunction={toggleMarkOptions}
        />
      </ModalLayout>
      <ModalLayout modalOpen={modalsOpen.comments}>
        <MoreInformation
          closeFunction={toggleComments}
          sendFunction={callMarkApp}
          handleReasonChange={handleReasonChange}
          reason={reason}
        />
      </ModalLayout>
    </div>
  );
};

export default ViewMortgageApplicant;
