import { SelectChangeEvent } from "@mui/material";
import React from "react";
import styles from "./dropdown.module.scss";

interface OptionProps {
  value?: string;
  label?: string;
  onSelect?: (event: React.MouseEvent, selectedValue?: string) => any;
  className?: string;
}

const Option: React.FC<OptionProps> = ({
  onSelect = (event, selectedValue) => {},
  value = "None",
  label = "None",
  className,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    onSelect(event, value);
  };

  return (
    <li className={`${styles.option} ${className}`} onClick={handleClick}>
      {label}
    </li>
  );
};

export interface DropdownOption {
  value: any;
  label: string;
  slug?: any;
  amount?: any;
}

export interface DropdownProps {
  options?: any[];
  optionsObj?: DropdownOption[];
  useObjectValues?: boolean;
  variant?: 1 | 2;
  name?: string;
  placeholder?: string;
  value?: any;
  valueCollector?: (value?: any) => any;
  className?: string;
  StatusIsRent?: React.Dispatch<React.SetStateAction<boolean>>;
  StatusIsSale?: React.Dispatch<React.SetStateAction<boolean>>;
  selectProps?: React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >;
}

const Dropdown: React.FC<DropdownProps> = ({
  options = [],
  optionsObj,
  useObjectValues,
  variant = 1,
  className = "",
  name,
  placeholder,
  value,
  valueCollector,
  selectProps,
  StatusIsRent,
  StatusIsSale,
}) => {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setValue] = React.useState<string | undefined>();

  // Effect for update mode
  React.useEffect(() => {
    setValue(value);
    valueCollector && valueCollector(value); 
  }, [value]);

  const listRef = React.useRef<any>();

  const handleClickOutside = (event: MouseEvent) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [open]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const onSelect = (event: React.MouseEvent, selectedValue?: string) => {
    setValue(selectedValue);
    valueCollector && valueCollector(selectedValue);
  };
  const onSelectPlaceholder = () => {
    setValue(undefined);
    valueCollector && valueCollector(undefined);
  };
  const onChange = (event: React.FormEvent<HTMLSelectElement>) => {
    if (event.currentTarget.value === "Rent") {
      StatusIsRent && StatusIsRent(true);
      StatusIsSale && StatusIsSale(false);
    } else if (event.currentTarget.value === "Sale") {
      StatusIsSale && StatusIsSale(true);
      StatusIsRent && StatusIsRent(false);
    } else {
      StatusIsSale && StatusIsSale(false);
      StatusIsRent && StatusIsRent(false);
    }
    setValue(event.currentTarget.value);
    valueCollector && valueCollector(event.currentTarget.value);
  };

  let openStyle = open ? styles.open : "";
  let variantStyle = variant === 2 ? styles.variant2 : "";
  let mainStyle = variant === 2 ? "short-by text-center" : styles.inputName;
  let niceSelect = variant === 2 ? "nice-select" : styles.inputName;

  return (
    <div className={mainStyle}>
      <select
        {...selectProps}
        value={inputValue}
        placeholder={placeholder}
        className={`${styles.niceSelect} ${className}`}
        onChange={onChange}
        style={{ display: "block" }}
        name={name}
      >
        <option value="">{placeholder}</option>
        {useObjectValues &&
          optionsObj?.map((item, index) => (
            <option key={`opt${index}`} value={item.value}>
              {item.label}
            </option>
          ))}
        {!useObjectValues &&
          options.map((item, index) => (
            <option key={`opt${index}`}>{item}</option>
          ))}
      </select>
      <div
        className={`${styles.niceSelect} ${variantStyle}`}
        style={{ display: "none" }}
        tabIndex={0}
        onClick={toggleOpen}
        ref={listRef}
      >
        {!inputValue && <span className={styles.current}>{placeholder}</span>}
        {inputValue && <span className={styles.current}>{inputValue}</span>}
        <ul className={`${styles.list} ${openStyle}`}>
          {
            <Option
              value={placeholder}
              label={placeholder}
              onSelect={onSelectPlaceholder}
              className={variant === 2 ? styles.variant2Option : ""}
            />
          }
          {options.map((item, index) => (
            <Option
              value={item}
              label={item}
              onSelect={onSelect}
              className={variant === 2 ? styles.variant2Option : ""}
              key={`Op${index}`}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
