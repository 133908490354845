import styles from "./smarthome.module.scss";
import ImageSliderSeecond from "../../components/ImageSliderSecond/ImageSliderSecond";
import package1 from "../../assets/package1.png";
import package2 from "../../assets/package2.png";
import package3 from "../../assets/package3.png";
import package4 from "../../assets/package4.png";
import goldpackage from "../../assets/gold-package.png";
import silverpackage from "../../assets/silver-package.png";
import bronzepackage from "../../assets/bronze-package.png";
import developerpackage from "../../assets/developers-package.png";
import diamondpackage from "../../assets/diamond-package.png";
import formBg from "../../assets/form-image-bg.png";
import { useContext, useState } from "react";
import dropdown from "../../assets/dropdown.svg";
import ModalLayout from "../../components/ModalLayout";
import PopUp from "../../components/Insurance/PopUp";
import TextInput from "../../components/FormElements/TextInput";
import { combineDateAndTime, findFirstEmptyField, toDateStringRestriction } from "../../utils/generalUtils";
import { Dayjs } from "dayjs";
import { TimePicker } from "antd";
import { Textfit } from "react-textfit";
import ToastContext from "../../contexts/ToastContext";
import LoadingContext from "../../contexts/LoadingContext";
import { ApplySmartHomesReq } from "../../types/samertHomes";
import { applySmartHomes } from "../../swr/smartHomes";
import { useHistory } from "react-router";

const token = localStorage.getItem("token") as string

const SmartHomeComponent = () => {
  const history = useHistory()

  // Context
  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);

  const imagesForSecondSlider = [
    "../../assets/firstImageSlide.png",
    "../../assets/secondImageSlide.png",
    "../../assets/thirdImageSlide.png",
  ];

  const status = [{ name: "Building in Progress", value: 1 }, { name: "Complete/Fully Furnished", value: 2 }];

  const houses = [
    { name: "Fully Detached", value: 1 },
    { name: "Semi Detached", value: 2 },
    { name: "Terraced", value: 3 },
    { name: "Masionette", value: 4 },
    { name: "Bungalow", value: 5 },
    { name: "Apartment", value: 6 },
    { name: "Penthouse", value: 7 },
  ];

  type ListItem = typeof status[0]

  const automation = [{ name: "Full House Automation", value: 1 }, { name: "Selected Hardware", value: 2 }];

  const [showStatus, setShowStatus] = useState(false);

  const [statusOption, setStatusOption] = useState<ListItem>();

  const [houseOption, setHouseOption] = useState<ListItem>();
  const [showHouse, setShowHouse] = useState(false);

  const packages = [
    { name: "Buddy Starter Package 1 (N598,000)", value: 1 },
    { name: "Buddy Starter Package 2 (N980,000)", value: 2 },
    { name: "Buddy Tech Package (N655,000)", value: 3 },
    { name: "Buddy Boss Chick Package (N1,030,000)", value: 4 },
    { name: "Bronze Package (N3.5M-4.2M)", value: 5 },
    { name: "Silver Package (N4.5M-5.5M)", value: 6 },
    { name: "Gold Package (N6M-8M)", value: 7 },
    { name: "Developers Package (N4.3M)", value: 8 },
    { name: "Diamond Package", value: 9 }
  ];

  const [packageOption, setPackageOption] = useState<ListItem>();
  const [showPackage, setShowPackage] = useState(false);

  const [floorsOrStoreys, setFloorsOrStoreys] = useState(0);
  const handleFloorOrStoreysChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFloorsOrStoreys(event.target.valueAsNumber);
  };

  const [bedrooms, setBedrooms] = useState(0);
  const handleBedroomsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBedrooms(event.target.valueAsNumber);
  };

  const [publicSpaces, setPublicSpaces] = useState(0);
  const handlePublicSpacesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPublicSpaces(event.target.valueAsNumber);
  };

  const [automatedBedrooms, setAutomatedBedrooms] = useState(0);
  const handleAutomatedBedroomChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAutomatedBedrooms(event.target.valueAsNumber);
  };

  const [automatedPublicSpaces, setAutomatedPublicSpaces] = useState(0);
  const handleAutomatedPublicSpacesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAutomatedPublicSpaces(event.target.valueAsNumber);
  };

  const [automationOption, setAutomationOption] = useState<ListItem>();
  const [showAutomation, setShowAutomation] = useState(false);

  const [stateOfBuilding, setStateOfBuilding] = useState("");
  const handleStateOfBuildingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStateOfBuilding(event.target.value);
  };

  const [areaOfBuilding, setAreaOfBuilding] = useState("");
  const handleAreaOfBuildingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAreaOfBuilding(event.target.value);
  };

  const [buildingAddress, setBuildingAddress] = useState("");
  const handleBuildingAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBuildingAddress(event.target.value);
  };

  const [installationTime, setInstallationTime] = useState<Dayjs | null>(null);
  const [installationDate, setInstallationDate] = useState<string | null>(null);

  const confirmPaymentCall = async (request: ApplySmartHomesReq, token: string) => {
    const { hasEmptyField, key } = findFirstEmptyField(request)
    if (hasEmptyField) {
      openError(`Please fill the ${key} field`)
      return
    }
    try {
      setLoading(true)
      const { data, error } = await applySmartHomes(request, token)
      console.log(data, "apply smart homes", error, "error apply smart homes");

      if (data && data.succeeded) {
        setInstallationDate(null)
        setInstallationTime(null)
        setAutomationOption(undefined)
        setAreaOfBuilding("")
        setAutomatedBedrooms(0)
        setAutomatedPublicSpaces(0)
        setBedrooms(0)
        setFloorsOrStoreys(0)
        setStateOfBuilding("")
        setHouseOption(undefined)
        setPackageOption(undefined)
        setStatusOption(undefined)
        setLoading(false)
        setBuildingAddress("")
        setPublicSpaces(0)
        openSuccess('Your application has been submitted for processing')
        history.push("/")
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Enables smooth scrolling
        });
        return
      }

      if (error) {
        setLoading(false)
        openError('an error occurred submitting application')
        return
      }

      setLoading(false)
      openError('an error occurred submitting application')
    } catch (error) {
      setLoading(false)
      openError('an error occurred submitting application')
    }

  }

  return (
    <section className={styles.container}>
      <div className={styles.first_div}>
        <div className={styles.text_and_button}>
          <span className={styles.header}>
            Simplify Your Life, Smarten Up Your Home.
          </span>
          <span className={styles.subtitle}>
            Ever wondered what it's like to live in a home that anticipates your
            every need? Explore the limitless possibilities of our smart home
            automation.
          </span>
          <button className={`btn theme-btn-1 rounded ${styles.exploreBtn}`}>
            Explore Packages
          </button>
        </div>
        <div className={styles.imageContainer}>
          <ImageSliderSeecond images={imagesForSecondSlider} />
        </div>
      </div>

      <div>
        <span className={styles.subheader}>Our Packages</span>
        <div className={styles.spacing}>
          <div className={styles.package_item}>
            <img src={package1} alt="" />
            <span className={styles.package_header}>
              Buddy Starter Package 1 (N598,000)
            </span>
            <ul>
              <li>1 Camera</li>
              <li>1 Smart door lock</li>
              <li>1 Google mesh (access point)</li>
              <li>1 Gateway</li>
              <li>1 Smart video door bell</li>
              <li>1 Google mini (voice assistant control)</li>
            </ul>
          </div>
          <div className={styles.package_item}>
            <img src={package2} alt="" />
            <span className={styles.package_header}>
              Buddy Starter Package 2 (N980,000)
            </span>
            <ul>
              <li>2 Cameras</li>
              <li>1 Smart door lock</li>
              <li>2 Google mesh (access point)</li>
              <li>1 Smart video door bell</li>
              <li>1 Google mini</li>
              <li>14 x 4 Smart display control module</li>
            </ul>
          </div>
        </div>
        <div className={styles.spacing}>
          <div className={styles.package_item}>
            <img src={package3} alt="" />
            <span className={styles.package_header}>
              Buddy Tech Package (N655,000)
            </span>
            <ul>
              <li>1 Google mesh (access point)</li>
              <li>1 Camera</li>
              <li>1 Smart socket</li>
              <li>1 Smart door lock</li>
              <li>1 Smart 4 gang switch</li>
              <li>1 Smart wireless charger</li>
              <li>1 Protocol gateway</li>
              <li>1 PIR sensor</li>
            </ul>
          </div>
          <div className={styles.package_item}>
            <img src={package4} alt="" />
            <span className={styles.package_header}>
              Buddy Boss Chick Package (N1,030,000)
            </span>
            <ul>
              <li>1 Smart diffuser</li>
              <li>1 Smart door lock</li>
              <li>1 PIR sensor</li>
              <li>1 Google mesh (access point)</li>
              <li>1 Google mini</li>
              <li>14 x 4 Smart display control module</li>
              <li>1 Camera</li>
              <li>1 Smart towel dryer</li>
            </ul>
          </div>
        </div>

        <div className={styles.green_box}>
          <ul>
            <li>
              <strong>
                Kindly be advised that installation fees are not included in
                these packages listed above.
              </strong>
            </li>
            <li>
              These packages listed above are exclusively offered for Lagos and
              Abuja. Additionally, it's important to note that certain areas
              within these locations may necessitate an additional
              transportation charge.
            </li>
            <li>
              These packages listed above are solely applicable to buildings
              with residents currently occupying them.{" "}
            </li>
          </ul>
        </div>

        <div className={styles.horizontal}>{/* <hr /> */}</div>

        <div className={styles.package_deals}>
          <div className={styles.package_deals_image}>
            <img src={bronzepackage} alt="" />
          </div>
          <div className={styles.white_card}>
            <span className={styles.package_deals_header}>
              Bronze Package: N3.5M-4.2M
            </span>
            <span className={styles.package_deals_subtitle}>
              The bronze covers your living area, ante-room, dining, kitchen,
              compound and master bedroom.
            </span>
            <span className={styles.package_deals_reason}>
              What you get in this package:
            </span>
            <span className={styles.package_deals_subtitle}>
              Lighting switches, AC switches, Water Heater switches, Google
              minis, Nest Hub, 360 Camera, P2P Camera, Smart door lock.
            </span>
          </div>
        </div>

        <div className={styles.package_deals}>
          <div className={styles.white_card}>
            <span className={styles.package_deals_header}>
              Silver Package N4.5M-5.5M
            </span>
            <span className={styles.package_deals_subtitle}>
              The silver covers your living area, ante-room, dining room,
              kitchen, compound, master bedroom and one other bedroom.
            </span>
            <span className={styles.package_deals_reason}>
              What you get in this package:
            </span>
            <span className={styles.package_deals_subtitle}>
              Lighting switches, AC switches, Water Heater switches, Google
              minis, Nest Hub, 360 Camera, P2P Camera, Smart door lock and
              curtains
            </span>
          </div>
          <div className={styles.package_deals_image}>
            <img src={silverpackage} alt="" />
          </div>
        </div>

        <div className={styles.package_deals}>
          <div className={styles.package_deals_image}>
            <img src={goldpackage} alt="" />
          </div>
          <div className={styles.white_card}>
            <span className={styles.package_deals_header}>
              Gold Package N6M-8M
            </span>
            <span className={styles.package_deals_subtitle}>
              The gold package covers your living area, ante-room, dining room,
              kitchen, compound, master bedroom, upstairs living area and two
              other bedrooms. Comes with more screens and hardware.
            </span>
            <span className={styles.package_deals_reason}>
              What you get in this package:
            </span>
            <span className={styles.package_deals_subtitle}>
              Lighting switches, AC switches, Water Heater switches, Google
              minis, Nest Hub, 360 Camera, P2P Camera, Smart door lock and
              Curtains.
            </span>
          </div>
        </div>

        <div className={styles.package_deals}>
          <div className={styles.white_card}>
            <span className={styles.package_deals_header}>
              Developers Package N4.3M
            </span>
            <span className={styles.package_deals_subtitle}>
              This package is our special discounted package designed for
              developers building to sell. It covers living area, kitchen,
              outdoor light control, ante room, dining room, master bedroom and
              two other bedrooms.
            </span>
          </div>
          <div className={styles.package_deals_image}>
            <img src={developerpackage} alt="" />
          </div>
        </div>

        <div className={styles.package_deals}>
          <div className={styles.package_deals_image}>
            <img src={diamondpackage} alt="" />
          </div>
          <div className={styles.white_card}>
            <span className={styles.package_deals_header}>Diamond Package</span>
            <span className={styles.package_deals_subtitle}>
              Diamond package covers the entire home and comes with a lot more
              premium hardware. The cost is dependent upon the client's choice.
            </span>
            <span className={styles.package_deals_subtitle}>
              ritzmansmarthomes.com/packages/ - Refer to this link to see what
              we have in mind concerning this package.
            </span>
          </div>
        </div>

        <div className={styles.project_container}>
          <img src={formBg} alt="" className={styles.formBg} />
          <div className={styles.project_form}>
            <span className={styles.project_form_header}>
              Do You Want To Start A Project With Us?
            </span>
            <span className={styles.project_form_subheader}>
              Kindly fill the form below with the correct details
            </span>

            <div className={styles.inputRow}>
              <div className={[styles.rel_form, styles.grid_item].join(" ")}>
                {/* <label
                  htmlFor="state-input"
                  aria-required
                  className={styles.required}
                >
                  What's the Status of the Building?
                </label> */}
                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  What's the Status of the Building?
                </Textfit>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                  }}
                  id="state-input"
                  readOnly
                  value={statusOption?.name}
                />
                <img
                  src={dropdown}
                  alt=""
                  onClick={() => setShowStatus(!showStatus)}
                />
              </div>
              <div className={[styles.rel_form, styles.grid_item].join(" ")}>
                {/* <label
                  htmlFor="state-input"
                  aria-required
                  className={styles.required}
                >
                  What type of House?
                </label> */}
                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  What type of House?
                </Textfit>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                  }}
                  id="state-input"
                  readOnly
                  value={houseOption?.name}
                />
                <img
                  src={dropdown}
                  alt=""
                  onClick={() => setShowHouse(!showHouse)}
                />
              </div>
              <div className={styles.grid_item}>
                {/* <label htmlFor="" className={styles.required}>
                  How Many Floors/Storeys in The Building? vghvhfghvfhvh jvhjgjvghv
                </label> */}
                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  How Many Floors/Storeys in The Building?
                </Textfit>
                <input
                  type="number"
                  style={{
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                    width: "100%",
                    padding: 18,
                  }}
                  value={floorsOrStoreys}
                  onChange={handleFloorOrStoreysChange}
                />
              </div>
              <div className={styles.grid_item}>
                {/* <label htmlFor="" className={styles.required}>
                  How Many Bedrooms Are in The Building?
                </label> */}
                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  How Many Bedrooms Are in The Building?
                </Textfit>
                <input
                  type="number"
                  style={{
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                    width: "100%",
                    padding: 18,
                  }}
                  value={bedrooms}
                  onChange={handleBedroomsChange}
                />
              </div>
              <div className={styles.grid_item}>
                {/* <label htmlFor="" className={styles.required}>
                  How many public spaces?
                </label> */}
                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  How many public spaces?
                </Textfit>
                <input
                  type="number"
                  style={{
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                    width: "100%",
                    padding: 18,
                  }}
                  value={publicSpaces}
                  onChange={handlePublicSpacesChange}
                />
              </div>
              <div className={styles.grid_item}>
                {/* <label htmlFor="" className={styles.required}>
                  Number of Bedrooms to Automate
                </label> */}
                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  Number of Bedrooms to Automate
                </Textfit>
                <input
                  type="number"
                  style={{
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                    width: "100%",
                    padding: 18,
                  }}
                  value={automatedBedrooms}
                  onChange={handleAutomatedBedroomChange}
                />
              </div>

              <div className={styles.grid_item}>
                {/* <label htmlFor="" className={styles.required}>
                  Number of Public Spaces to Automate
                </label> */}
                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  Number of Public Spaces to Automate
                </Textfit>
                <input
                  type="number"
                  style={{
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                    width: "100%",
                    padding: 18,
                  }}
                  value={automatedPublicSpaces}
                  onChange={handleAutomatedPublicSpacesChange}
                />
              </div>

              <div className={[styles.rel_form, styles.grid_item].join(" ")}>
                {/* <label className={styles.required}>
                  What type of Automation?
                </label> */}
                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  What type of Automation?
                </Textfit>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                  }}
                  readOnly
                  value={automationOption?.name}
                />
                <img
                  src={dropdown}
                  alt=""
                  onClick={() => setShowAutomation(!showAutomation)}
                />
              </div>

              <div className={styles.grid_item}>
                {/* <label htmlFor="" className={styles.required}>
                  Which State is The Building Located?
                </label> */}
                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  Which State is The Building Located?
                </Textfit>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                    width: "100%",
                    padding: 18,
                  }}
                  value={stateOfBuilding}
                  onChange={handleStateOfBuildingChange}
                />
              </div>

              <div className={styles.grid_item}>
                {/* <label htmlFor="" className={styles.required}>
                  Which Area is The Building Located?
                </label> */}
                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  Which Area is The Building Located?
                </Textfit>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                    width: "100%",
                    padding: 18,
                  }}
                  value={areaOfBuilding}
                  onChange={handleAreaOfBuildingChange}
                />
              </div>

              <div className={styles.grid_item}>
                {/* <label htmlFor="" className={styles.required}>
                  Building Address
                </label> */}

                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  Building Address
                </Textfit>

                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                    width: "100%",
                    padding: 18,
                  }}
                  value={buildingAddress}
                  onChange={handleBuildingAddressChange}
                />
              </div>

              <div className="w-full">
                {/* <label className={styles.required}>
                  Select Date For Installation
                </label> */}
                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  Select Date For Installation
                </Textfit>
                <TextInput
                  name="installationDate"
                  hideDefaultAdornment
                  type="date"
                  min={toDateStringRestriction(new Date())}
                  onChange={(e) => setInstallationDate(e.target.value)}
                // className="mb-30"
                // error={errorState.paymentStartDate}
                // required={errorState.paymentStartDate}
                // onChange={unhighlight}
                />
              </div>

              <div className={styles.grid_item}>
                {/* <label htmlFor="" className={styles.required}>
                  Select Time For Installation
                </label> */}
                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  Select Time For Installation
                </Textfit>
                <TimePicker
                  // label="Pick Time"
                  // value={installationTime }
                  onChange={(newValue) => setInstallationTime(newValue)}
                  use12Hours
                  format="h:mm a"
                  style={{
                    width: "100%",
                    padding: 20,
                    borderColor: "black",
                  }}
                />
              </div>

              <div className={[styles.rel_form, styles.grid_item].join(" ")}>
                {/* <label
                  htmlFor="state-input"
                  aria-required
                  className={styles.required}
                >
                  Select A Package
                </label> */}
                <Textfit
                  max={12}
                  mode="single"
                  forceSingleModeWidth={false}
                  className={styles.required}
                >
                  Select A Package
                </Textfit>
                <input
                  type="text"
                  style={{
                    borderRadius: 10,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                    width: "100%",
                  }}
                  id="state-input"
                  readOnly
                  value={packageOption?.name}
                />
                <img
                  src={dropdown}
                  alt=""
                  onClick={() => setShowPackage(!showPackage)}
                />
              </div>
            </div>
            <div className={styles.project_submit_btn}>
              <button
                className={`btn theme-btn-1 rounded ${styles.submit_btn}`}
                type="button"
                onClick={() => confirmPaymentCall({
                  buildingCompletionStatus: statusOption?.value as number,
                  houseType: houseOption?.value as number,
                  floors: floorsOrStoreys,
                  totalBedRooms: bedrooms,
                  totalPublicSpaces: publicSpaces,
                  bedroomsToAutomate: automatedBedrooms,
                  spacesToAutomate: automatedPublicSpaces,
                  automationType: automationOption?.value as number,
                  state: stateOfBuilding,
                  area: areaOfBuilding,
                  address: buildingAddress,
                  installationDate: combineDateAndTime(installationDate as string, installationTime),
                  smartPackage: packageOption?.value as number
                }, token)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalLayout modalOpen={showStatus === true}>
        <PopUp
          options={status.map(item => item.name)}
          click={(selectedOption: string) => {
            const selected = status.find(item => item.name === selectedOption)
            setStatusOption(selected);
            setShowStatus(false);
          }}
        />
      </ModalLayout>

      <ModalLayout modalOpen={showHouse === true}>
        <PopUp
          options={houses.map(item => item.name)}
          click={(selectedOption: string) => {
            const selected = houses.find(item => item.name === selectedOption)
            setHouseOption(selected);
            setShowHouse(false);
          }}
        />
      </ModalLayout>

      <ModalLayout modalOpen={showAutomation === true}>
        <PopUp
          options={automation.map(item => item.name)}
          click={(selectedOption: string) => {
            const selected = automation.find(item => item.name === selectedOption)
            setAutomationOption(selected);
            setShowAutomation(false);
          }}
        />
      </ModalLayout>

      <ModalLayout modalOpen={showPackage === true}>
        <PopUp
          options={packages.map(item => item.name)}
          click={(selectedOption: string) => {
            const selected = packages.find(item => item.name === selectedOption)
            setPackageOption(selected);
            setShowPackage(false);
          }}
        />
      </ModalLayout>
    </section>
  );
};

export default SmartHomeComponent;
