import React, { useState } from "react";
import Amenities from "../../components/PropertyElements/Amenities";
import Description from "../../components/PropertyElements/Description";
import Detail from "../../components/PropertyElements/Detail";
import PropertyElement from "../../components/PropertyElements/Element";
import PropertyMetadata from "../../components/PropertyMetadata";
import Location from "../../components/PropertyElements/Location";
import CommentSection from "../../components/PropertyElements/Comments/CommentSection";
import CommentForm from "../../components/PropertyElements/Comments/CommentForm";
import Reviews from "../../components/PropertyElements/Reviews";
import Gallery from "../../components/PropertyElements/Gallery";
import ProductSidebar from "../../components/ProductSidebar";
import AuthourWidget from "../../components/Widgets/Authour";
import BookingWidget from "../../components/Widgets/Booking";
import TopRatedProductsWidget from "../../components/Widgets/TopRatedProducts";
import EditListingWidget from "../../components/Widgets/EditListing";
import UpdateProductDialog from "../../components/Dialogs/UpdateProduct";
import AddListingsView from "../AddListingsView";
import { Property } from "../../types/Property";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { errorMessage } from "../../utils/propertyUtils";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import LoadingScreen from "../../components/LoadingScreen";
import DeleteProductDialog from "../../components/Dialogs/DeleteProduct";
import { deleteProperty, updateProperty } from "../../state/thunks/property";
import { useHistory } from "react-router";
import jwt, { Jwt } from "jsonwebtoken";
import { useRating } from "../../swr/ratings";
import Skeleton from "@mui/material/Skeleton";
import { useSWRConfig } from "swr";
import { isTokenExpired } from "../../utils/tokenUtils";
import PurchaseWidget from "../../components/Widgets/Purchase";
import ToastContext from "../../contexts/ToastContext";
import ImageUploadDialog from "../../components/Dialogs/ImageUploadDialog";
import RelatedProperties from "../../components/PropertyElements/RelatedProperties";
import { getComments, makePropertyFeatured } from "../../swr/properties";
import { GetCommentByRating } from "../../swr/properties";
import LoadingContext from "../../contexts/LoadingContext";
import Modal from "react-modal";
import IsFeaturedProperty from "../AddListingsView/IsFeaturedProperty";
import { resetDeleteResponse } from "../../state/reducers/propertySlice";
import Mortgage from "../../views/MortgageView";
import MortgageSuccess from "./MortgageSuccess";
import { getWalletDetails } from "../../swr/wallet";
import ListerWidget from "../../components/Widgets/Lister";
import ReportWidget from "../../components/Widgets/Report";
import ReportModal from "./reportModal";

const ProductDetail: React.FC<{ property?: Property }> = ({ property }) => {
  const token = localStorage.getItem("token") as string;
  const isLoggedIn = token?.length > 0;
  // Contexts
  const { openError, openSuccess } = React.useContext(ToastContext);
  const { setLoading } = React.useContext(LoadingContext);

  // State
  const [open, setOpen] = React.useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
  const [errOpen, setErrOpen] = React.useState<boolean>(false);
  const [successOpen, setSuccessOpen] = React.useState<boolean>(false);
  const [imageOpen, setImageOpen] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [ratingcomments, setratingComments] =
    React.useState<GetCommentByRating>({
      succeeded: false,
      message: "",
      errors: "",
      data: [
        {
          id: 13,
          userId: "",
          user: "",
          propertiesId: 1,
          properties: null,
          rating: 5,
          comment: "Okay",
        },
      ],
    });
  // Hooks
  let history = useHistory();
  const { mutate } = useSWRConfig();

  const [mortgageSuccess, setMortgageSuccess] = useState(false);

  const closeMortgageSuccess = () => {
    setMortgageSuccess(false);
    history.goBack();
  };

  // Handlers
  const handleDialogClose = () => {
    setOpen(false);
  };
  const handleOnUpdate = () => {
    setOpen(true);
  };
  const handleOnDelete = () => {
    setDeleteOpen(true);
  };
  const handleClose = () => {
    setErrOpen(false);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };
  const handleImageOpen = () => {
    setImageOpen(true);
  };
  const handleImageClose = () => {
    setImageOpen(false);
  };
  const imageUpdateCallback = () => {
    dispatch(
      updateProperty({
        request: { ...property, isEnabled: property?.isEnabled },
        token: localStorage.getItem("token") || undefined,
        id: property?.id || 0,
      })
    );
  };

  const handleReportNoAuth = () => {
    openError("Please log in to report listing");
    history.push("/signin");
  };

  const handleOpenModal = () => {
    isLoggedIn === true ? setOpenModal(true) : handleReportNoAuth();
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const {
    updateError,
    updateResponse,
    updateLoading,
    deleteError,
    deleteResponse,
    deleteLoading,
    images,
  } = useSelector((state: RootState) => state.property);

  const [pin, setPin] = useState("");

  const [openIsFeaturedModal, setOpenIsFeaturedModal] = React.useState(false);

  const openIsFeatured = () => {
    setOpenIsFeaturedModal(true);
  };
  const closeIsFeatured = () => {
    setOpenIsFeaturedModal(false);
  };

  const makePropertyFeaturedCall = async (id: number) => {
    if (!pin) {
      openError("Please enter your pin");
      return;
    }
    if (pin && pin.length !== 4) {
      openError("Please enter correct transaction pin");
      return;
    }
    closeIsFeatured();

    try {
      setLoading(true);
      const data = await makePropertyFeatured({
        token,
        propertyId: id,
        pin,
      });

      if (data && data.succeeded === true) {
        setLoading(false);
        openSuccess(data.message);
        history.push("/account#liton_tab_1_6");
        return;
      } else {
        setLoading(false);
        openError(
          data.message
            ? data.message
            : data.Message
            ? data.Message
            : "error making payment"
        );
        closeIsFeatured();
        history.push("/");
      }
    } catch (error) {
      setLoading(false);
      openError(
        //@ts-ignore
        error.response.message ? error.response.message : "error making payment"
      );
      closeIsFeatured();
      history.push("/");
    }
  };

  const handleNav = () => {
    setLoading(true);
    if (!property) {
      openError("Property is not available");
      setLoading(false);
      return;
    }
    if (property.availability) {
      if (token) {
        getWalletDetails(token).then((result) => {
          if (
            result.data &&
            result.data.data &&
            result.data.data.virtual_account_no
          ) {
            setLoading(false);
            history.push(`/payment?property=${property.id}`);
          } else {
            setLoading(false);
          }

          if (result.error) {
            setLoading(false);
            if (
              result.error.response &&
              result.error.response.data &&
              result.error.response?.data.Message === "01"
            ) {
              setLoading(false);
              history.push("/account#liton_tab_1_6");
            } else {
              openError("Could not fetch wallet");
              setLoading(false);
            }
          }
        });
      } else {
        setLoading(false);
        openError("Please sign in to make payment");
      }
    } else {
      setLoading(false);
      openError("Property has been paid for");
    }
  };

  // swr
  const { data, isLoading, isError } = useRating(property?.id);

  // Handlers involving Redux
  const handleDelete = () => {
    let token = localStorage.getItem("token") || undefined;
    if (property?.id) {
      dispatch(deleteProperty({ token, id: property.id }));
    }
  };

  React.useEffect(() => {
    const getCommentsByProperty = async () => {
      if (property) {
        const res = (await getComments(
          property?.id,
          token!,
          null,
          null,
          true
        )) as any;
        setratingComments(res);
      }
    };

    getCommentsByProperty();
  }, []);

  // console.log(ratingcomments);

  // Effect for product update
  React.useEffect(() => {
    let token = localStorage.getItem("token") || undefined;
    if (deleteResponse) {
      openSuccess("Successfully deleted property");
      dispatch(resetDeleteResponse());
      history.push("/properties");
    }

    if (updateError || deleteError) {
      setErrOpen(true);
      setSuccessOpen(false);
    }

    if (!updateError && updateResponse) {
      mutate(`/api/properties/${property?.id}`);
    }
  }, [updateResponse, updateError, deleteResponse, deleteError, images]);

  // Authour details
  const User = property?.user;
  const fullName = User?.firstName + " " + User?.lastName;

  // Logged in user
  let ownerIsLoggedIn: boolean = false;

  let isMortgageProp = property && property.status === "Mortgage";

  if (token) {
    let decodedToken: Jwt | null = jwt.decode(token, { complete: true });
    if (decodedToken && typeof decodedToken.payload === "object") {
      ownerIsLoggedIn = decodedToken.payload.email === property?.user.email;
    }
  }

  const categories = [
    "Inaccurate information",
    "Fradulent listing",
    "Inappropriate content",
  ];

  console.log(property?.id);

  return (
    <>
      <section className="container">
        {/** Image Upload Dialog */}
        <ImageUploadDialog
          propertyId={property?.id || 0}
          onClose={handleImageClose}
          open={imageOpen}
          attachments={property?.attachments}
          uploadCallback={imageUpdateCallback}
          deleteCallback={imageUpdateCallback}
        />

        {/** Update Property Dialog */}
        <UpdateProductDialog
          open={open}
          onClose={handleDialogClose}
          title="UPDATE LISTING"
        >
          {updateLoading && <LoadingScreen />}
          <Snackbar
            open={errOpen}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={handleClose}
          >
            <Alert severity="error" onClose={handleClose}>
              {errorMessage(
                updateError,
                "An error occured while updating your property"
              )}
            </Alert>
          </Snackbar>
          <Snackbar
            open={successOpen}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={handleSuccessClose}
          >
            <Alert severity="success" onClose={handleSuccessClose}>
              Property updated successfully
            </Alert>
          </Snackbar>
          <AddListingsView
            isUpdate
            property={property}
            close={handleDialogClose}
          />
        </UpdateProductDialog>

        {/* Delete Property Dialog*/}
        <DeleteProductDialog
          open={deleteOpen}
          onDelete={handleDelete}
          onClose={handleDeleteClose}
          title={`DELETE PROPERTY`}
          context={`Are you sure you want to delete property: ${property?.title}`}
        >
          {deleteLoading && <LoadingScreen />}
          <Snackbar
            open={errOpen}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={handleClose}
          >
            <Alert severity="error" onClose={handleClose}>
              {errorMessage(
                deleteError,
                "An error occurred while deleting your property"
              )}
            </Alert>
          </Snackbar>
        </DeleteProductDialog>

        <div className="row">
          {/** Left side */}
          <div className="col-lg-8 col-md-12">
            <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
              {/** Metadata */}
              {/* <PropertyMetadata
                            category={property?.type} status={property?.status}
                            dateAdded={property?.dateAdded && new Date(property.dateAdded)}
                            comments={property?.comments} rating={data?.data?.rating || 0}
                            id={property?.id || 0}
                        /> */}
              <PropertyMetadata
                category={property?.type}
                status={property?.status}
                dateAdded={property?.dateAdded && new Date(property.dateAdded)}
                comments={
                  ratingcomments && ratingcomments.data
                    ? ratingcomments.data.length
                    : undefined
                }
                id={property?.id || 0}
                verified={property?.isEnabled}
              />

              {/** Heading with Address */}
              <h1>{property?.title}</h1>
              <label>
                <span className="ltn__secondary-color">
                  <i className="flaticon-pin"></i>
                </span>
                {`${property?.city}, ${property?.state}`}
              </label>

              {/** Description */}
              <PropertyElement title="Description">
                <Description description={property?.description} />
              </PropertyElement>

              {/** Property Detail */}
              <PropertyElement title="Property Detail">
                <Detail {...property} />
              </PropertyElement>

              {isMortgageProp && !ownerIsLoggedIn && (
                <PropertyElement title="Apply For Mortgage">
                  <Mortgage
                    propertyId={property?.id}
                    price={property?.price}
                    property={property}
                    setMortgageSuccess={setMortgageSuccess}
                  />
                </PropertyElement>
              )}

              {/** Gallery */}
              <PropertyElement title="From Our Gallery">
                <Gallery attachments={property?.attachments} />
              </PropertyElement>

              {/** Amenities */}
              <PropertyElement title="Amenities">
                <Amenities amenities={property?.amenities} />
              </PropertyElement>

              {/** Amenities */}
              <PropertyElement title="Location">
                <Location address={property?.address} />
              </PropertyElement>

              {/** Ratings and review */}
              <div className="ltn__shop-details-tab-content-inner--- ltn__shop-details-tab-inner-2 ltn__product-details-review-inner mb-60">
                <PropertyElement title="Customer Reviews">
                  {isLoading && (
                    <Skeleton variant="rectangular" width="100%">
                      <Reviews />
                    </Skeleton>
                  )}
                  {!isLoading && (
                    <Reviews
                      rating={data?.data?.averageRating || 0}
                      count={data?.data?.count || 0}
                    />
                  )}
                </PropertyElement>

                <hr />

                {/** Comments Area */}
                <CommentSection
                  propertyId={property?.id ?? 0}
                  comments={ratingcomments && ratingcomments.data}
                />

                {/** Add Review */}
                {!ownerIsLoggedIn && !isTokenExpired(token) && property && (
                  <CommentForm id={property.id} />
                )}

                <h4 className="title-2">Related Properties</h4>
                <RelatedProperties propertyStatus={property?.status} />
              </div> 
            </div>
          </div>

          {/** Product Sidebar */}
          <div className="col-lg-4">
            <ProductSidebar>
              {!ownerIsLoggedIn && <BookingWidget id={property?.id} />}
              <TopRatedProductsWidget />
              {ownerIsLoggedIn && (
                <EditListingWidget
                  onUpdate={handleOnUpdate}
                  onDelete={handleOnDelete}
                  onImageUpdate={handleImageOpen}
                  onRenewFeaturedSubscription={openIsFeatured}
                />
              )}
              {!ownerIsLoggedIn && !isMortgageProp && (
                <PurchaseWidget
                  propertyId={property?.id}
                  status={property?.status}
                  availability={property?.availability}
                  handleNav={handleNav}
                />
              )}
              {<ReportWidget handleModal={handleOpenModal} />}
              <AuthourWidget
                fullName={fullName}
                mobile={User?.phoneNumber}
                email={User?.email}
                imageURL={User?.imageUrl}
                description=""
                history={history}
                listerId={property?.user?.id}
                // rating={User?.rating}
              />
              {/* {<ListerWidget listerId={property?.user?.id} history={history} />} */}
            </ProductSidebar>
          </div>
        </div>
      </section>
      <Modal
        isOpen={openIsFeaturedModal}
        onRequestClose={closeIsFeatured}
        style={{
          content: {
            top: "54%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // backgroundColor: "var(--ltn__secondary-color)",
            // border: '3px solid rgb(120, 248, 120);',
            borderRadius: "0.7em",
            zIndex: "9999",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="isFeatured Modal"
        // appElement={document.getElementById('root') as HTMLElement}
      >
        <IsFeaturedProperty
          closeModal={closeIsFeatured}
          makePropertyFeaturedCall={makePropertyFeaturedCall}
          id={property?.id!}
          pin={pin}
          setPin={setPin}
        />
      </Modal>
      <Modal
        isOpen={mortgageSuccess}
        onRequestClose={closeMortgageSuccess}
        style={{
          content: {
            top: "54%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // backgroundColor: "var(--ltn__secondary-color)",
            // border: '3px solid rgb(120, 248, 120);',
            borderRadius: "0.7em",
            zIndex: "20",
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
          },
          overlay: {
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          },
        }}
        contentLabel="isFeatured Modal"
        // appElement={document.getElementById('root') as HTMLElement}
      >
        <MortgageSuccess closeModal={closeMortgageSuccess} />
      </Modal>
      <ReportModal
        isOpen={openModal}
        handleClose={handleCloseModal}
        id={property?.id!}
        token={token}
      />
    </>
  );
};

export default ProductDetail;
