import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./mortgage.module.scss";

const MoreInformation = ({
  reason,
  sendFunction,
  closeFunction,
  handleReasonChange,
}: {
  reason: string;
  sendFunction: () => void;
  closeFunction: () => void;
  handleReasonChange: (text: string) => void;
}) => {
  return (
    <div className={styles["more-container"]}>
      <div className={styles["cancel-container"]}>
        <div
          className={styles["cancel-button"]}
          role="button"
          onClick={closeFunction}
        >
          <CloseIcon />
        </div>
      </div>

      <p className={styles.header}>More Information</p>
      <span className={styles.instruction}>
        Kindly state the additional information(s) required for this mortgage
        application
      </span>

      <textarea
        className={styles.input}
        value={reason}
        onChange={(e) => handleReasonChange(e.target.value)}
        autoComplete="off"
      />

      <div
        className={styles["send-button"]}
        role="button"
        onClick={sendFunction}
      >
        <span className={styles["send-text"]}>Send</span>
      </div>
    </div>
  );
};

export default MoreInformation;
